import { styled } from "@mui/material"
import Alert from "@mui/material/Alert"

/**
 * Blank alert with transparent background
 *
 * @remarks
 * MUI theming only supports styling for info, success, warning and error.
 */
const BlankAlert = styled(Alert)(({ theme }) => ({
  border: "none",
  background: "transparent",
  padding: 0,
  color: theme.typography.body1.color,
}))

export default BlankAlert
