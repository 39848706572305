import React, { ReactElement, useState } from "react"
import T from "@mui/material/Typography"
import { styled } from "@mui/material/styles"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import ErrorIcon from "@mui/icons-material/Error"
import { navigate, PageProps } from "gatsby"
import Link from "../../../components/Link"
import Button from "../../../components/design-system/Button"
import { PATHNAME_APPLICATION } from "./index"
import SEO from "../../../components/SEO"
import { BasePageContext } from "../../../interfaces/PageContext"
import { Status } from "../../../utility/calculateStatuses"
import PulseLoader from "../../../components/PulseSpinner"
import useStageStatuses from "../../../hooks/useStageStatuses"
import { email } from "@ap-frontend/questions"
import ButtonWrapper from "../../../components/ButtonWrapper"
import BlankAlert from "../../../components/BlankAlert"

const PAYMENT_CONFIRMATION_TIMEOUT = 30
export const PATHNAME_APPLICATION_PAYMENT = "/application/payment"

const PaymentProcessing = (): ReactElement => {
  return (
    <>
      <T variant="h1" component="h1">
        We&apos;re processing your payment
      </T>
      <PulseLoader />
    </>
  )
}

const WarningT = styled(T)(({ theme }) => ({
  background: theme.palette.grey[100],
  padding: theme.spacing(3),
  maxWidth: 556,
}))

/**
 * The payment status page (landing page that WPM redirects back to).
 * Waits `PAYMENT_CONFIRMATION_TIMEOUT` for a successful payment status whilst displaying
 * "Processing your payment". When confirmation is received, display "Payment received".
 * Otherwise, when we timeout, display "Payment confirmation not received".
 */
export const PaymentStatus = ({ questions }: BasePageContext): ReactElement => {
  const [timedOut, setTimedOut] = useState(false)
  const { payment: paymentStatus } = PaymentStatus.useStageStatuses(questions)

  // run timer to wait for success status
  React.useEffect(() => {
    const timer = setTimeout(
      () => setTimedOut(true),
      PaymentStatus.paymentConfirmationTimeout * 1000
    )
    return () => clearTimeout(timer)
  }, [])

  switch (paymentStatus) {
    case undefined:
      return <PaymentProcessing />
    case Status.NotRequired:
      // If they don't need to pay, shouldn't be here
      navigate(PATHNAME_APPLICATION)
      return <></>
    case Status.Error:
      // The applicants need to pay but they have no unused tokens with which to pay. Let the
      // `payment` page handle this.
      navigate(PATHNAME_APPLICATION_PAYMENT)
      return <></>
    case Status.Complete:
      return (
        <>
          <T variant="h1" component="h1">
            <CheckCircleOutlineIcon color="primary" fontSize="large" />
            Payment received
          </T>
          <BlankAlert
            data-test-id="payment-alert"
            icon={<ErrorIcon fontSize="inherit" />}
          >
            You have not submitted your application yet.
          </BlankAlert>
          <T variant="body2">
            {"To do this, go to the 'Submit your application' section."}
          </T>
          <ButtonWrapper>
            <Button
              role="link"
              onClick={() => navigate(PATHNAME_APPLICATION)}
              data-test-button="continue"
            >
              Continue
            </Button>
          </ButtonWrapper>
        </>
      )
  }

  // We've timed out - display messaging that confirmation wasn't received.
  if (timedOut) {
    return (
      <>
        <T variant="h1" component="h1">
          <ErrorIcon fontSize="large" />
          There is a problem with your payment
        </T>
        <WarningT variant="body2">
          We&apos;re sorry. We could not record the payment in our system.
          <br />
          You will not be able to submit your application until we process the
          payment.
          <br />
          We&apos;ll email you when you can continue with your application.
          <br />
          If you have not received an email within 2 working days, email:{" "}
          <Link href={`mailto:${email}`}>{email}</Link>
        </WarningT>
        <ButtonWrapper>
          <Button
            role="link"
            onClick={() => navigate(PATHNAME_APPLICATION)}
            data-test-button="continue"
          >
            Return to your application
          </Button>
        </ButtonWrapper>
      </>
    )
  }

  // We're waiting to receive payment confirmation.
  return <PaymentProcessing />
}

// Setting these as static members allows us to mock during component testing
PaymentStatus.paymentConfirmationTimeout = PAYMENT_CONFIRMATION_TIMEOUT
PaymentStatus.useStageStatuses = useStageStatuses

export default function PaymentStatusPage({
  pageContext,
}: PageProps<never, BasePageContext>): ReactElement {
  return (
    <>
      <SEO title="Application fee payment" />
      <PaymentStatus questions={pageContext.questions} />
    </>
  )
}
